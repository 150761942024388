import React from "react";
import QuestionArea from "./QuestionArea/QuestionArea";

function App() {
  return (
    <>
      <QuestionArea />
    </>
  );
}

export default App;
